import React from "react";
import theme from "theme";
import { Theme, Text, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Velocity Street Rentals
			</title>
			<meta name={"description"} content={"Відчуйте гострі відчуття з кожною милею"} />
			<meta property={"og:title"} content={"Velocity Street Rentals"} />
			<meta property={"og:description"} content={"Відчуйте гострі відчуття з кожною милею"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-10">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Text margin="0px 0px 15px 0px" font="normal 700 42px/1.2 --fontFamily-sans" text-align="center">
				Ознайомтеся з нашими послугами
			</Text>
			<Text margin="0px 0px 50px 0px" font="--lead" color="#626970" text-align="center">
				У Velocity Street Rentals ми надаємо більше, ніж просто оренду автомобіля; ми забезпечуємо неперевершені враження від водіння, які починаються з того моменту, коли ви входите в наші двері. Наші послуги створені, щоб задовольнити вимогливих ентузіастів, гарантуючи, що кожен аспект вашої оренди відповідає вашим найвищим очікуванням. Незалежно від того, чи шукаєте ви захоплюючу поїздку для особливої ​​події чи просто відпочинок на вихідних, у нас є ідеальний маслкар, який чекає на вас.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="24px"
				lg-grid-gap="25px 25px"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/1-3.jpg?v=2024-06-14T11:27:28.674Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						align-items="center"
						margin="0px 0px 10px 0px"
						lg-margin="0px 0px 15px 0px"
					/>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Різноманітний вибір: Все найкраще з Детройта. Від купе-поні та седанів до потужних позашляховиків та пікапів, наш автопарк обладнаний таким чином, щоб задовольнити смаки всіх наших клієнтів.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/1-2.jpg?v=2024-06-14T11:27:28.682Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Підготовка до продуктивності: Кожен автомобіль налаштований до досконалості, пропонуючи не просто поїздку, а досвід, який резонує з ревом потужності та плавністю передової техніки.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-1.jpg?v=2024-06-14T11:27:28.736Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Взірцевий стан: Ми підтримуємо наші транспортні засоби в ідеальному стані, як естетично, так і механічно, гарантуючи, що вони завжди готові до виставок.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-3.jpg?v=2024-06-14T11:27:28.673Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Простий процес: від бронювання до повернення, наші процеси оптимізовані та орієнтовані на клієнта, забезпечуючи плавну та приємну роботу.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text">
				Долучайтеся, щоб дізнатися більше
			</Override>
			<Override slot="text1">
				Це лише короткий огляд того, що ми пропонуємо в Velocity Street Rentals. Відкрийте для себе повну інформацію про те, що Velocity Street Rentals може запропонувати, зв’язавшись з нами або відвідавши нас особисто. Ми запрошуємо вас відчути повний спектр наших послуг і відчути відчуття від водіння сучасного маслкара. Наша команда з радістю познайомить вас із нашим автопарком і допоможе вибрати ідеальний автомобіль для ваших потреб.
			</Override>
			<Override slot="box2" align-self="center" />
			<Override slot="image" src="https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/1-1%20%281%29.jpg?v=2024-06-14T11:27:28.666Z" />
		</Components.Cta>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});